<template>
  <header @click="$router.go(-1)">
    <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.70706 0.292844C10.0976 0.683342 10.0977 1.31651 9.70716 1.70706L2.41421 9.00101L9.70706 16.2928C10.0976 16.6833 10.0977 17.3165 9.70716 17.7071C9.31666 18.0976 8.68349 18.0977 8.29294 17.7072L0.292942 9.70826C-0.0976092 9.31776 -0.0976529 8.6846 0.292844 8.29405L8.29284 0.292942C8.68334 -0.0976092 9.31651 -0.0976529 9.70706 0.292844Z" fill="black"/>
    </svg>
  </header>
</template>
<script>
export default {
  name: 'SpHeader',
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  components: {
  },
  computed: {
  },
  watch: {

  },

}
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
header {
  display: none;
}
@media only screen and (max-width:640px) {
  header {
    display: block;
    position: fixed;
    top:0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    height: 54px;
    padding: 18px 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #F2F4F5;
  }

}
</style>
