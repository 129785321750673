export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async userCertification ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}user/certification`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async checkDuplicatedLoginId ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.MEMBER_API_URL}user/login-id/${payload.id}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async createUser ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.MEMBER_API_URL}user`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getServiceTerms ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.MEMBER_API_URL}service/terms`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
