<template>
<div class="selectbox">
    <select
    :id="uuid"
    :value="value"
    @input="input"
    v-on="listeners"
    v-bind="attrs">
        <slot></slot>
    </select>
</div>
</template>

<script>
export default {
  name: 'SpSelect',
  inheritAttrs: false,
  props: {
    value: { type: null, default: undefined },
    id: { type: String, default: '' },
  },
  data () {
    return {
      uuid: null,
    }
  },
  created () {
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    },
  },
  computed: {
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      return this.$attrs
    },
  },
}
</script>

<style lang="scss" scoped>
.selectbox {
  position: relative;
  display: inline-block;
  min-width: 100px;
  width: 100%;
  box-sizing: border-box;
  // vertical-align: top;
  // margin:0 0 0 0;
}

.selectbox:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 9px;
  width: 0;
  height: 0;
  margin-top: -2.5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #22a29c;
}

.selectbox select {
  padding: 4px 0;
  margin: 4px 0;
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 14px;
  line-height: 16px;
  text-indent: 9px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.selectbox select:focus {
  border-color: #14BEB3;
  animation: shadow 0.1s ease-in-out forwards;
}
@keyframes shadow {
  to {
    box-shadow: 0 0 6px #14BEB3;
  }
}
select option[value=""][disabled] {
  display: none;
}
</style>
