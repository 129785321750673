<template>
<div>
  <popup
  v-for="modal, i in modals"
  :key="i"
  :title="modal.title"
  :description="modal.message"
  :closeOnClickOutside="false"
  @close="modals.splice(i)">
    <div class="button-group">
      <button
      v-if="modal.actionCode == 'ALERT'"
      type="button"
      class="btn_g on"
      @click="modals.splice(i)">확인</button>
      <button
      v-if="modal.actionCode == 'LOGIN'"
      class="btn_g on"
      @click="() => {
        modals.splice(i)
        $router.push('/login').catch(e => {})
      }">확인</button>
    </div>
  </popup>
</div>
</template>
<script>
export default {
  name: 'SpModals',
  data () {
    return {

    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {
    modals () {
      return this.$store.state.modals
    },
  },
  watch: {

  },
}
</script>
