<template>
  <div @click.self="close('outside')" class="layer-wrap">
    <div class="layer-s-container s_layer">
        <div class="layer-body">
            <strong v-show="title" class="title_layer">{{ title }}</strong>
            <p class="desription" v-show="description" v-html="description"></p>
            <div class="wrap_buttom" ref="popup">
                <slot></slot>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
  },
  mounted () {
    this.$refs.popup.querySelector('button:last-child').focus()
  },
  methods: {
    close (from) {
      if (from == 'outside' && this.closeOnClickOutside) {
        this.$emit('close')
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.desription {
  white-space: pre-line;
}
</style>
